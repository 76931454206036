import { $$ } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-QuestionsAndAnswers${suffix}`;

$$(dcn()).forEach(($el) => {
    const $questions = $$(dcn('-question'), $el);
    const $titles = $$(dcn('-questionTitle'), $el);
    const $contents = $$(dcn('-questionContent'), $el);

    let hash;

    const handleTitleClick = (i, _event) => {
        const $question = $questions[i];

        const isExpanded = $question.classList.contains('is-expanded');

        $question.classList.toggle('is-expanded', !isExpanded);

        hash = `#${$question.id}`;

        const questionId = $question.id;

        $question.id = null;

        window.location.hash = hash;

        setTimeout(() => {
            $question.id = questionId;
        });
    };

    const handleMutationOrResize = () => {
        $contents.forEach(($content) => {
            $content.style.maxHeight = `${$content.scrollHeight}px`;
        });
    };

    const handleHashChange = () => {
        if (hash === window.location.hash) {
            return;
        }

        hash = window.location.hash;

        $titles.forEach(($title, i) => {
            if ($title.hash !== hash) {
                return;
            }

            const $question = $questions[i];

            $question.classList.add('is-expanded');

            $question.scrollIntoView({ behavior: 'smooth' });
        });
    };

    $titles.forEach(($title, i) => {
        $title.addEventListener('click', (event) => handleTitleClick(i, event));
    });

    const mutationObserver = new MutationObserver(handleMutationOrResize);
    mutationObserver.observe($el, {
        attributes: true,
        childList: true,
        subtree: true,
    });

    window.addEventListener('resize', handleMutationOrResize);

    window.addEventListener('hashchange', handleHashChange);

    handleMutationOrResize();
    handleHashChange();
});
