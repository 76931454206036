window.addEventListener('load', () => {
    const usernameInput = document.querySelector('#user_login');
    const emailInput = document.querySelector('#user_email');

    if (!usernameInput || !emailInput) {
        return;
    }

    emailInput.addEventListener('change', () => {
        usernameInput.value = emailInput.value;
    });

    usernameInput.value = emailInput.value;
});
