import { $, $$ } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-ProductHeader${suffix}`;

const ANIMATION_DURATION = 250;

$$(dcn()).forEach((el) => {
    const slides = $$(dcn('-slide'), el);

    if (slides.length <= 1) {
        return;
    }

    const container = $(dcn('-slideContainer'), el);
    const prevButton = $(dcn('-prevSlideButton'), el);
    const nextButton = $(dcn('-nextSlideButton'), el);
    const indicators = $$(dcn('-slideIndicator'), el);
    const indicatorInners = $$(dcn('-slideIndicatorInner'), el);

    const slideCount = slides.length;
    const maxI = slideCount - 1;

    let animationFrame;
    let startTimestamp;
    let isAnimating = false;
    let startI;
    let currentI = 0;
    let targetI = 0;
    let duration;

    const onAnimationFrame = (timestamp) => {
        animationFrame = null;

        if (!isAnimating) {
            return;
        }

        if (startTimestamp === null) {
            startTimestamp = timestamp;
        }

        const t = Math.min(1, (timestamp - startTimestamp) / duration);
        const easedT = 1 - (1 - t) * (1 - t);

        currentI = startI + (targetI - startI) * easedT;

        container.style.setProperty(
            `transform`,
            `translateX(${-currentI * 100}%)`
        );

        if (t < 1) {
            animationFrame = requestAnimationFrame(onAnimationFrame);

            return;
        }

        isAnimating = false;
    };

    const startAnimation = (newTargetI) => {
        while (newTargetI < 0) {
            newTargetI += slideCount;
        }
        while (newTargetI > maxI) {
            newTargetI -= slideCount;
        }

        if (newTargetI === targetI) {
            return;
        }

        isAnimating = true;
        startTimestamp = null;
        startI = currentI;
        targetI = newTargetI;

        const absDI = Math.abs(targetI - startI);
        if (absDI < 1) {
            duration = ANIMATION_DURATION * absDI;
        } else {
            duration = ANIMATION_DURATION;
        }

        indicatorInners.forEach((inner, indicatorI) => {
            inner.classList.toggle('bg-theme-25', targetI !== indicatorI);
            inner.classList.toggle('bg-theme-darker', targetI === indicatorI);
        });

        if (!animationFrame) {
            animationFrame = requestAnimationFrame(onAnimationFrame);
        }
    };

    const onClickPrev = (event) => {
        event.preventDefault();

        startAnimation(targetI - 1);
    };

    const onClickNext = (event) => {
        event.preventDefault();

        startAnimation(targetI + 1);
    };

    const onClickBullet = (i) => (event) => {
        event.preventDefault();

        startAnimation(i);
    };

    prevButton.addEventListener('click', onClickPrev);
    nextButton.addEventListener('click', onClickNext);

    indicators.forEach((indicator, i) =>
        indicator.addEventListener('click', onClickBullet(i))
    );
});
