import { $$ } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-ArchiveHeader${suffix}`;

$$(dcn()).forEach(($el) => {
    $$(dcn('-filters'), $el).forEach(($filterContainer) =>
        $$(dcn('-filter'), $filterContainer).forEach(($filter) => {
            if (!$filter.classList.contains('is-current')) {
                return;
            }

            const left =
                $filter.offsetLeft -
                ($filterContainer.getBoundingClientRect().width -
                    $filter.getBoundingClientRect().width) /
                    2;

            $filterContainer.scrollTo({ left, behavior: 'smooth' });
        })
    );
});
