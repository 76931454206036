/* eslint-disable max-classes-per-file */
import { $, $$, createElement } from '../../utils';

import marker2x from '../../../../images/marker@2x.png';
import markerActive2x from '../../../../images/marker-active@2x.png';

const { mapboxgl, turf } = window;

const cn = (suffix = '') => `views-templates-PageStores${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

const maxZoom = 15;

class PageStores {
    $el;

    $mapAnchor;

    map;

    $form;

    $typeInputs;

    $countryInputs;

    $regionInputContainer;

    $regionInput;

    $findClosestContainer;

    defaultBounds;

    stores;

    hoverStoreId;

    hoverClusterId;

    activeStoreId;

    geolocateControl;

    currentGeolocation;

    currentType;

    currentCountry;

    currentRegion;

    constructor($el) {
        this.$el = $el;
        this.$mapAnchor = $(dcn('-mapAnchor'), $el);
        this.$form = $(dcn('-form'), $el);
        this.$typeInputs = $$(dcn('-typeInput'), $el);
        this.$countryInputs = $$(dcn('-countryInput'), $el);
        this.$regionInputContainer = $(dcn('-regionInputContainer'), $el);
        this.$regionInput = $(dcn('-regionInput'), $el);
        this.$regionNames = $$(dcn('-regionName'), $el);
        this.$findClosestContainer = $(dcn('-findClosestContainer'), this.$el);
        this.$findClosestButton = $(dcn('-findClosestButton'), this.$el);

        const $stores = $$(dcn('-store'), $el);
        const $popupTemplates = $$(dcn('-popupTemplate'), $el);

        this.locale = JSON.parse($el.dataset.locale);
        this.regions = JSON.parse($el.dataset.regions);

        this.stores = $stores.map(($store, i) => ({
            i,
            id: parseInt($store.dataset.id, 10),
            $el: $store,
            $link: $(dcn('-storeLink'), $store),
            $distanceLabel: $(dcn('-distanceLabel'), $store),
            $popupTemplate: $popupTemplates[i],
            isOnline: $store.hasAttribute('data-is-online'),
            isClinic: $store.hasAttribute('data-is-clinic'),
            coordinates: $store.dataset.lng &&
                $store.dataset.lat && [$store.dataset.lng, $store.dataset.lat],
            country: $store.dataset.country,
            region: $store.dataset.region,
        }));

        this.defaultBounds = new mapboxgl.LngLatBounds();
        this.stores
            .filter((s) => s.coordinates)
            .forEach((c) => this.defaultBounds.extend(c.coordinates));

        this.initMap();
    }

    // Load map

    initMap() {
        const { accessToken } = this.$el.dataset;

        mapboxgl.accessToken = accessToken;

        const $map = $(dcn('-map'), this.$el);

        this.map = new mapboxgl.Map({
            container: $map,
            locale: this.locale,
            style: 'mapbox://styles/mapbox/light-v10',
            scrollZoom: false,
            boxZoom: false,
            touchPitch: false,
            dragRotate: false,
        });
        this.map.touchZoomRotate.disableRotation();

        const navigationControl = new mapboxgl.NavigationControl({
            showCompass: false,
        });
        this.map.addControl(navigationControl);

        this.map.setPadding({ top: 50, right: 50, bottom: 20, left: 20 });
        this.map.fitBounds(this.defaultBounds);

        this.map.on('load', () => this.handleMapLoad());
    }

    async handleMapLoad() {
        await this.loadImage('marker', marker2x);
        await this.loadImage('marker-active', markerActive2x);

        this.map.addSource('stores', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: this.stores
                    .filter((c) => c.coordinates)
                    .map((c) => ({
                        type: 'Feature',
                        id: c.id,
                        geometry: {
                            type: 'Point',
                            coordinates: c.coordinates,
                        },
                    })),
            },
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 40,
        });

        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'stores',
            filter: ['has', 'point_count'],
            paint: {
                'circle-radius': 20,
                'circle-color': '#d4edf9',
                'circle-stroke-color': '#165f7e',
                'circle-stroke-width': 2,
            },
        });

        this.map.addLayer({
            id: 'cluster-counts',
            type: 'symbol',
            source: 'stores',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12,
            },
            paint: {
                'text-color': '#165f7e',
            },
        });

        this.map.addLayer({
            id: 'stores',
            type: 'symbol',
            source: 'stores',
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': 'marker',
                'icon-anchor': 'bottom',
                'icon-allow-overlap': true,
            },
        });

        this.addEventListeners();

        this.updateList();
    }

    addEventListeners() {
        this.map.on('mousemove', 'clusters', (e) =>
            this.handleClustersLayerMouseMove(e)
        );
        this.map.on('mouseleave', 'clusters', (e) =>
            this.handleClustersLayerMouseLeave(e)
        );
        this.map.on('click', 'clusters', (e) =>
            this.handleClustersLayerClick(e)
        );

        this.map.on('mousemove', 'stores', (e) =>
            this.handleStoresLayerMouseMove(e)
        );
        this.map.on('mouseleave', 'stores', (e) =>
            this.handleStoresLayerMouseLeave(e)
        );
        this.map.on('click', 'stores', (e) => this.handleStoresLayerClick(e));

        this.map.on('click', (e) => this.handleMapClick(e));
        this.map.on('zoomstart', (e) => this.handleZoomStart(e));

        this.$typeInputs.forEach(($i) =>
            $i.addEventListener('change', () => this.handleTypeChange())
        );
        this.$countryInputs.forEach(($i) =>
            $i.addEventListener('change', () => this.handleCountryChange())
        );
        this.$regionInput.addEventListener('change', () =>
            this.handleRegionChange()
        );

        this.$findClosestButton.addEventListener('click', (e) =>
            this.handleFindClosestClick(e)
        );

        this.stores.forEach((s) =>
            s.$link?.addEventListener('click', (e) =>
                this.handleStoreLinkClick(s, e)
            )
        );
    }

    // Utils

    loadImage(name, url) {
        return new Promise((resolve, reject) => {
            this.map.loadImage(url, (error, image) => {
                if (error) {
                    reject(error);
                } else {
                    this.map.addImage(name, image, { pixelRatio: 2 });

                    resolve();
                }
            });
        });
    }

    setActiveStore(id) {
        const store = this.stores.find((c) => c.id === id);

        if (this.activeStoreId) {
            this.stores
                .find((c) => c.id === this.activeStoreId)
                .popup?.remove();
        }

        if (!store.popup) {
            store.popup = new mapboxgl.Popup({
                focusAfterOpen: false,
                closeButton: false,
                anchor: 'bottom',
                offset: [0, -50],
            })
                .setLngLat(store.coordinates)
                .setDOMContent(store.$popupTemplate.content.cloneNode(true));
        }

        store.popup.addTo(this.map);

        this.map.setLayoutProperty('stores', 'icon-image', [
            'match',
            ['id'],
            id,
            'marker-active',
            'marker',
        ]);

        this.map.setLayoutProperty('stores', 'symbol-sort-key', [
            'match',
            ['id'],
            id,
            1,
            0,
        ]);

        this.map.easeTo({
            center: store.coordinates,
            offset: [0, 75],
            zoom: maxZoom,
        });

        this.hoverStoreId = null;
        this.activeStoreId = id;
    }

    clearActiveStore() {
        if (this.activeStoreId) {
            this.stores
                .find((c) => c.id === this.activeStoreId)
                .popup?.remove();
            this.activeStoreId = null;
        }

        this.map.setLayoutProperty('stores', 'icon-image', 'marker');
    }

    startGeolocating() {
        if (!this.geolocateControl) {
            this.geolocateControl = new mapboxgl.GeolocateControl({});
            this.geolocateControl.on('geolocate', (e) =>
                this.handleGeolocate(e)
            );
            this.geolocateControl.on('error', (e) =>
                this.handleGeolocateError(e)
            );
            this.geolocateControl.onAdd(this.map);
        }

        this.$findClosestButton.classList.add('theme-buttons-dark');

        setTimeout(() => {
            this.geolocateControl.trigger();
        });
    }

    stopGeolocating() {
        if (!this.geolocateControl) {
            return;
        }

        this.geolocateControl.onRemove();

        this.$findClosestButton.classList.remove('theme-buttons-dark');

        this.stores.forEach((store) => {
            store.distance = null;
            store.$distanceLabel.textContent = '';
        });

        this.geolocateControl = null;
        this.currentGeolocation = null;
    }

    easeToBounds(bounds) {
        const camera = this.map.cameraForBounds(bounds, { maxZoom });

        this.map.easeTo(camera);
    }

    scrollMapIntoView() {
        this.$mapAnchor.scrollIntoView({ behavior: 'smooth' });
    }

    updateList() {
        const data = new FormData(this.$form);
        const type = data.get('type');
        const country = data.get('country');
        let region = data.get('region') || null;

        const online = type === 'online';
        const clinic = type === 'clinic';
        const geolocating = clinic && !!this.currentGeolocation;

        if (
            this.currentType === type &&
            this.currentCountry === country &&
            this.currentRegion === region &&
            !geolocating
        ) {
            return;
        }

        if (this.currentCountry !== country) {
            region = null;
        }

        if (this.currentCountry !== country) {
            const $emptyOption = createElement('option', { value: '' }, '-');
            const $options = this.regions
                .filter((r) => r.country === country)
                .map((r) => createElement('option', { value: r.id }, r.name));
            this.$regionInput.replaceChildren($emptyOption, ...$options);
        }

        if (this.currentCountry !== country || this.currentRegion !== region) {
            this.clearActiveStore();

            if (region) {
                const bounds = new mapboxgl.LngLatBounds();

                this.stores
                    .filter((s) => s.region === region)
                    .forEach((s) => bounds.extend(s.coordinates));

                this.easeToBounds(bounds);
            } else {
                this.easeToBounds(this.defaultBounds);
            }
        }

        this.$regionInputContainer.classList.toggle(
            'hidden',
            online || geolocating
        );

        this.$regionNames.forEach(($rn) =>
            $rn.classList.toggle(
                'hidden',
                online ||
                    geolocating ||
                    country !== $rn.dataset.country ||
                    region
            )
        );

        this.$findClosestContainer.classList.toggle('hidden', online);

        this.stores.forEach((s, i) => {
            const isType =
                (online && s.isOnline === online) ||
                (clinic && s.isClinic === clinic);

            const isCountry = geolocating || s.country === country;

            const isRegion = !region || s.region === region;

            s.$el.classList.toggle(
                'hidden',
                !isType || !isCountry || !isRegion
            );

            s.$el.style.order = geolocating ? i : null;
        });

        this.currentType = type;
        this.currentCountry = country;
        this.currentRegion = region;
    }

    // Cluster Events

    handleClustersLayerMouseMove(e) {
        const { id } = e.features[0];

        if (id === this.hoverClusterId) {
            return;
        }

        this.map.getCanvas().style.cursor = 'pointer';

        this.map.setPaintProperty('clusters', 'circle-color', [
            'match',
            ['id'],
            id,
            '#a9dbf3',
            '#d4edf9',
        ]);
    }

    handleClustersLayerMouseLeave() {
        this.map.getCanvas().style.cursor = '';

        this.map.setPaintProperty('clusters', 'circle-color', '#d4edf9');

        this.hoverClusterId = null;
    }

    handleClustersLayerClick(e) {
        e.preventDefault();

        const feature = e.features[0];

        const { id } = feature;
        const pointCount = feature.properties.point_count;

        this.map
            .getSource('stores')
            .getClusterLeaves(id, pointCount, 0, (error, children) => {
                const bounds = new mapboxgl.LngLatBounds();
                children.forEach((c) => bounds.extend(c.geometry.coordinates));

                this.easeToBounds(bounds);
            });
    }

    // Store events

    handleStoresLayerMouseMove(e) {
        const { id } = e.features[0];

        if (id === this.hoverStoreId) {
            return;
        }

        const ids = [id];

        if (this.activeStoreId && this.activeStoreId !== id) {
            ids.push(this.activeStoreId);
        }

        this.map.getCanvas().style.cursor = 'pointer';

        this.map.setLayoutProperty('stores', 'icon-image', [
            'match',
            ['id'],
            ids,
            'marker-active',
            'marker',
        ]);

        this.hoverStoreId = id;
    }

    handleStoresLayerMouseLeave() {
        this.map.getCanvas().style.cursor = '';

        if (this.activeStoreId) {
            this.map.setLayoutProperty('stores', 'icon-image', [
                'match',
                ['id'],
                this.activeStoreId,
                'marker-active',
                'marker',
            ]);
        } else {
            this.map.setLayoutProperty('stores', 'icon-image', 'marker');
        }

        this.hoverStoreId = null;
    }

    handleStoresLayerClick(e) {
        e.preventDefault();

        const { id } = e.features[0];

        this.setActiveStore(id);
    }

    // Map events

    handleMapClick(e) {
        if (e.defaultPrevented) {
            return;
        }

        this.clearActiveStore();
    }

    handleZoomStart() {
        this.clearActiveStore();
    }

    // Form Events

    handleTypeChange() {
        this.stopGeolocating();

        this.updateList();
    }

    handleCountryChange() {
        this.stopGeolocating();

        this.updateList();
    }

    handleRegionChange() {
        this.stopGeolocating();

        this.updateList();
    }

    // Geolocate Events

    handleFindClosestClick(event) {
        event.preventDefault();

        this.startGeolocating();
    }

    handleGeolocate(event) {
        const geolocation = [event.coords.longitude, event.coords.latitude];

        this.currentGeolocation = geolocation;

        this.$countryInputs.forEach(($i) => {
            $i.checked = false;
        });
        this.$regionInput.value = '';

        this.stores.forEach((store) => {
            if (!store.coordinates) {
                store.distance = null;

                return;
            }

            store.distance = turf.distance(geolocation, store.coordinates);

            const intDistance = Math.round(store.distance);

            const formattedDistance = this.locale['App.Distance'].replace(
                '[km]',
                intDistance
            );

            store.$distanceLabel.textContent = formattedDistance;
        });

        this.stores.sort((a, b) => {
            if (!a.distance && !b.distance) {
                return 0;
            }
            if (!a.distance) {
                return 1;
            }
            if (!b.distance) {
                return -1;
            }

            return a.distance - b.distance;
        });

        this.updateList();

        const closestStores = this.stores.slice(0, 10);

        const bounds = new mapboxgl.LngLatBounds();
        closestStores.forEach((store) => {
            bounds.extend(store.coordinates);
        });

        this.easeToBounds(bounds);
    }

    handleGeolocateError() {
        // eslint-disable-next-line no-alert
        alert(this.locale['GeolocateControl.LocationNotAvailable']);

        this.stopGeolocating();
    }

    // List Events

    handleStoreLinkClick(store, event) {
        event.preventDefault();

        this.setActiveStore(store.id);
        this.scrollMapIntoView();
    }
}

$$(dcn()).map(($el) => new PageStores($el));
