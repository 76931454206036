const root = document.documentElement;

let oldWidth;

const measure = () => {
    const width = root.offsetWidth;

    if (oldWidth === width) {
        return;
    }

    root.style.setProperty('--app-layout-real100vw', `${width}px`);

    oldWidth = width;
};

const onAnimationFrame = () => {
    measure();

    requestAnimationFrame(onAnimationFrame);
};

requestAnimationFrame(onAnimationFrame);

measure();
