import { $, $$ } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-teacherLedCourseLoop${suffix}`;

$$(dcn()).forEach(($el) => {
    const $countryInputs = $$(dcn('-countryInput'), $el);
    const $coursesContainer = $(dcn('-courses'), $el);
    const $courses = $$(dcn('-course'), $el);

    const setCountry = (country) => {
        $coursesContainer.replaceChildren();

        $courses.forEach(
            ($c) =>
                (!country || $c.dataset.country === country) &&
                $coursesContainer.append($c)
        );
    };

    $countryInputs.forEach(($i) =>
        $i.addEventListener('change', () => setCountry($i.value))
    );
});
