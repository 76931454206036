import { throttle } from 'lodash';

import { $, $$ } from '../../../utils';

import { setThemeClass } from './site-header';

const dcn = (suffix = '') =>
    `.views-components-organisms-FrontPageHero${suffix}`;

const ANIMATION_DURATION = 5000;
const MOUSE_MOVE_THROTTLE_INTERVAL = 100;
const MOUSE_MOVE_TIMEOUT_INTERVAL = 1000;

$$(dcn()).forEach(($el) => {
    const $slides = $$(dcn('-slide'), $el);

    if ($slides.length <= 1) {
        return;
    }

    const $bulletsContainer = $(dcn('-bullets'), $el);
    const $bullets = $$(dcn('-bullet'), $el);
    const $progressOuters = $$(dcn('-bulletProgressOuter'), $el);
    const $progresses = $$(dcn('-bulletProgress'), $el);

    let currentI = 0;
    let currentSlideDuration = 0;
    let mouseMoveTimeout;
    let isTouchingOrMovingMouse = false;
    let isHoveringBullet = false;

    let lastTimestamp = null;

    const goTo = (i) => {
        if (i === currentI) {
            return;
        }

        if (i > $slides.length - 1) {
            i = 0;
        } else if (i < 0) {
            i = 0;
        }

        const $currentSlide = $slides[currentI];
        const $currentProgressOuter = $progressOuters[currentI];
        const $currentProgress = $progresses[currentI];

        $currentSlide.classList.remove('z-1');
        $currentSlide.classList.add('opacity-0');
        $currentSlide.classList.add('pointer-events-none');
        [
            $currentSlide.dataset.themeClass,
            $currentSlide.dataset.textThemeClass,
            $currentSlide.dataset.desktopThemeClass,
            $currentSlide.dataset.desktopTextThemeClass,
        ]
            .filter(Boolean)
            .forEach((c) => $bulletsContainer.classList.remove(c));
        $currentProgressOuter.classList.remove('bg-white');
        $currentProgressOuter.classList.add('bg-white/25');
        $currentProgress.style.width = '';

        const $nextSlide = $slides[i];
        const $nextBullet = $bullets[i];
        const $nextProgressOuter = $progressOuters[i];

        $nextSlide.classList.add('z-1');
        $nextSlide.classList.remove('opacity-0');
        $nextSlide.classList.remove('pointer-events-none');
        [
            $nextSlide.dataset.themeClass,
            $nextSlide.dataset.textThemeClass,
            $nextSlide.dataset.desktopThemeClass,
            $nextSlide.dataset.desktopTextThemeClass,
        ]
            .filter(Boolean)
            .forEach((c) => $bulletsContainer.classList.add(c));
        $nextProgressOuter.classList.remove('bg-white/25');
        $nextProgressOuter.classList.add('bg-white');

        $bulletsContainer.scrollTo({
            left:
                $nextBullet.getBoundingClientRect().left -
                $bullets[0].getBoundingClientRect().left,
            behavior: 'smooth',
        });

        setThemeClass($nextSlide.dataset.siteHeaderThemeClass, 'xs');
        setThemeClass($nextSlide.dataset.desktopSiteHeaderThemeClass, 'xl');

        currentI = i;
        currentSlideDuration = null;
    };

    const updateProgress = (timestamp) => {
        if (!lastTimestamp) {
            lastTimestamp = timestamp;

            return;
        }

        const frameDuration = timestamp - lastTimestamp;
        lastTimestamp = timestamp;

        if (isTouchingOrMovingMouse || isHoveringBullet) {
            return;
        }

        currentSlideDuration += frameDuration;

        const $progress = $progresses[currentI];

        const progress = Math.min(1, currentSlideDuration / ANIMATION_DURATION);
        const width = (progress * 100).toFixed(4);

        $progress.style.width = `${width}%`;

        if (currentSlideDuration < ANIMATION_DURATION) {
            return;
        }

        goTo(currentI + 1);
    };

    const handleMouseMoveTimeout = () => {
        isTouchingOrMovingMouse = false;
    };

    const handleTouchStart = () => {
        isTouchingOrMovingMouse = true;
    };

    const handleTouchEnd = () => {
        isTouchingOrMovingMouse = false;
    };

    const handleMouseMove = throttle(() => {
        if (mouseMoveTimeout) {
            clearInterval(mouseMoveTimeout);
        }

        isTouchingOrMovingMouse = true;

        mouseMoveTimeout = setTimeout(
            handleMouseMoveTimeout,
            MOUSE_MOVE_TIMEOUT_INTERVAL
        );
    }, MOUSE_MOVE_THROTTLE_INTERVAL);

    const handleAnimationFrame = (timestamp) => {
        updateProgress(timestamp);

        requestAnimationFrame(handleAnimationFrame);
    };

    $el.addEventListener('touchstart', handleTouchStart);
    $el.addEventListener('touchend', handleTouchEnd);
    $el.addEventListener('mousemove', handleMouseMove);

    const handleBulletMouseEnter = (_i, _event) => {
        isHoveringBullet = true;
    };

    const handleBulletMouseLeave = (_i, _event) => {
        isHoveringBullet = false;
    };

    const handleBulletClick = (i, event) => {
        event.preventDefault();

        goTo(i);
    };

    $bullets.forEach(($b, i) => {
        $b.addEventListener('mouseenter', (event) =>
            handleBulletMouseEnter(i, event)
        );
        $b.addEventListener('mouseleave', (event) =>
            handleBulletMouseLeave(i, event)
        );
        $b.addEventListener('click', (event) => handleBulletClick(i, event));
        $b.addEventListener('touchend', (event) => handleBulletClick(i, event));
    });

    requestAnimationFrame(handleAnimationFrame);
});
