import { $, $$, toggleClasses } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-AcademyHeader${suffix}`;

class AcademyHeader {
    constructor($el) {
        this.$ = (sel) => $(dcn(sel), $el);
        this.$$ = (sel) => $$(dcn(sel), $el);
        this.ajaxUrl = $el.dataset.ajaxUrl;

        this.initMenu();
        this.initNotifications();
    }

    // Menu

    initMenu() {
        this.menuIsOpen = false;

        this.$menu = this.$('-mobileMenu');

        this.$openMenuButtonContainer = this.$(
            '-openMobileMenuButtonContainer'
        );

        const $openMenuButton = this.$('-openMobileMenuButton');

        this.$closeMenuButtonContainer = this.$(
            '-closeMobileMenuButtonContainer'
        );
        const $closeMenuButton = this.$('-closeMobileMenuButton');

        this.menuOpenScrollY = null;

        $openMenuButton.addEventListener(
            'click',
            this.handleOpenMenuButtonClick
        );
        $closeMenuButton.addEventListener(
            'click',
            this.handleCloseMenuButtonClick
        );
    }

    toggleMenu = (open) => {
        if (open === this.menuIsOpen) {
            return;
        }

        const { body } = document;

        if (open) {
            this.menuOpenScrollY = window.scrollY;

            body.style.setProperty(
                'width',
                `${body.clientWidth}px`,
                'important'
            );
            body.style.setProperty('position', 'fixed', 'important');
            body.style.setProperty(
                'top',
                `-${this.menuOpenScrollY}px`,
                'important'
            );
        } else {
            body.style.position = '';
            body.style.top = '';
            body.style.width = '';

            window.scrollTo(0, this.menuOpenScrollY);
        }

        toggleClasses(this.$openMenuButtonContainer, {
            'opacity-0': open,
            'pointer-events-none': open,
        });

        toggleClasses(this.$closeMenuButtonContainer, {
            'opacity-0': !open,
            'pointer-events-none': !open,
        });

        toggleClasses(this.$menu, {
            'opacity-0': !open,
            'pointer-events-none': !open,
        });

        this.menuIsOpen = open;
    };

    handleOpenMenuButtonClick = (event) => {
        event.preventDefault();

        this.toggleMenu(true);
    };

    handleCloseMenuButtonClick = (event) => {
        event.preventDefault();

        this.toggleMenu(false);
    };

    // Notifications

    initNotifications() {
        this.$notifications = this.$('-notifications');

        if (!this.$notifications) {
            return;
        }

        const $notificationsButton = this.$('-notificationsButton');
        this.$notificationsPopup = this.$('-notificationsPopup');

        this.notificationsIsOpen = false;

        $notificationsButton.addEventListener(
            'click',
            this.handleNotificationsButtonClick
        );
    }

    handleNotificationsButtonClick = (event) => {
        event.preventDefault();

        this.$notificationsPopup.classList.remove('opacity-0');
        this.$notificationsPopup.classList.remove('pointer-events-none');

        setTimeout(this.startListeningToClicksOutsideNotificationsPopup, 0);
    };

    startListeningToClicksOutsideNotificationsPopup = () => {
        window.addEventListener(
            'click',
            this.maybeHandleClickOutsideNotifictionsPopup
        );
    };

    maybeHandleClickOutsideNotifictionsPopup = (event) => {
        if (this.$notificationsPopup.contains(event.target)) {
            return;
        }

        this.$notificationsPopup.classList.add('opacity-0');
        this.$notificationsPopup.classList.add('pointer-events-none');

        const body = new FormData();
        body.set('action', 'app/academy/dismiss-notifications');

        fetch(this.ajaxUrl, {
            method: 'POST',
            body,
        }).then(() => {});

        this.stopListeningToClicksOutsideNotificationsPopup();
    };

    stopListeningToClicksOutsideNotificationsPopup = () => {
        window.removeEventListener(
            'click',
            this.maybeHandleClickOutsideNotifictionsPopup
        );
    };
}

$$(dcn()).map(($el) => new AcademyHeader($el));
