import { $$ } from '../../../utils';

const dcn = (suffix = '') =>
    `.views-components-organisms-ShareButtons${suffix}`;

let popup = null;

$$(dcn('-button')).forEach((buttonEl) => {
    const width = 500;
    const height = 400;

    const onClick = (event) => {
        event.preventDefault();

        const url = buttonEl.href;

        if (popup) {
            popup.close();
        }

        const top = (window.screen.height - height) / 3;
        const left = (window.screen.width - width) / 2;

        popup = window.open(
            null,
            'socialPopup',
            `height=450, width=550, top=${top}, left=${left}, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`
        );
        popup.opener = null;
        popup.location = url;
    };

    buttonEl.addEventListener('click', onClick);
});
