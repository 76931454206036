import { $, $$ } from '../../../utils';

const dcn = (suffix = '') => `.views-components-organisms-Puffs${suffix}`;

$$(dcn()).forEach(($el) => {
    const $slides = $$(dcn('-slide'), $el);

    if ($slides.length <= 1) {
        return;
    }

    const $reversedSlides = $slides.slice().reverse();
    const $container = $(dcn('-slideContainer'), $el);
    const $buttonsContainer = $(dcn('-buttonsContainer'), $el);
    const $prevButton = $(dcn('-prevSlideButton'), $el);
    const $nextButton = $(dcn('-nextSlideButton'), $el);

    let containerPaddingLeft;
    let slideLeftPadding;
    let slidesPerPage;
    let slideWidth;

    const handleClickPrev = (event) => {
        event.preventDefault();

        const containerLeft = $container.getBoundingClientRect().left;

        let slideI = $reversedSlides.findIndex(
            ($slide) =>
                Math.ceil($slide.getBoundingClientRect().left) < containerLeft
        );

        if (slideI === -1) {
            slideI = 0;
        }

        slideI = Math.max(0, $slides.length - slideI - 1 - slidesPerPage + 1);

        $container.scrollTo({
            left: slideI * slideWidth + containerPaddingLeft - slideLeftPadding,
            behavior: 'smooth',
        });
    };

    const handleClickNext = (event) => {
        event.preventDefault();

        const containerRight = $container.getBoundingClientRect().right;

        let slideI = $slides.findIndex(
            ($slide) =>
                Math.floor($slide.getBoundingClientRect().right) >
                containerRight
        );

        if (slideI === -1) {
            slideI = 0;
        }

        $container.scrollTo({
            left: slideI * slideWidth + containerPaddingLeft - slideLeftPadding,
            behavior: 'smooth',
        });
    };

    const onResize = () => {
        const elRect = $el.getBoundingClientRect();
        const containerRect = $container.getBoundingClientRect();
        const visibleWidth =
            containerRect.width - (elRect.left - containerRect.left);
        containerPaddingLeft = parseFloat(
            getComputedStyle($container).paddingLeft
        );
        slideLeftPadding = parseFloat(getComputedStyle($slides[0]).paddingLeft);
        slideWidth = $slides[0].getBoundingClientRect().width;
        slidesPerPage = Math.floor(visibleWidth / slideWidth);
        const numberOfPages = Math.max(1, $slides.length - slidesPerPage + 1);

        $buttonsContainer.classList.toggle('lg:hidden', numberOfPages === 1);
    };

    $prevButton.addEventListener('click', handleClickPrev);
    $nextButton.addEventListener('click', handleClickNext);

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe($el);
    onResize();
});
