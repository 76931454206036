export const $ = (sel, node = null) => (node || document).querySelector(sel);
export const $$ = (sel, node = null) =>
    Array.from((node || document).querySelectorAll(sel));

export const lcfirst = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

export const toggleClasses = (el, classes) => {
    Object.entries(classes).forEach(([c, f]) => {
        el.classList.toggle(c, f);
    });
};

export const htmlToElement = (html) => {
    const template = document.createElement('template');

    template.innerHTML = html.trim();

    return template.content.firstChild;
};

export const htmlToElements = (html) => {
    const template = document.createElement('template');

    template.innerHTML = html.trim();

    return template.content.childNodes;
};

export const createElement = (tag, attributes = {}, ...children) => {
    const el = document.createElement(tag);

    Object.entries(attributes).forEach(([name, value]) => {
        if (name.startsWith('on') && typeof value === 'function') {
            el.addEventListener(lcfirst(name.substring(2)), value);
        } else {
            el.setAttribute(name, value);
        }
    });

    children.forEach((child) => {
        let childNode;

        if (child instanceof Node) {
            childNode = child;
        } else if (typeof child === 'string' || child instanceof String) {
            childNode = document.createTextNode(child);
        } else if (Array.isArray(child)) {
            childNode = createElement(...child);
        }

        if (!childNode) {
            return;
        }

        el.appendChild(childNode);
    });

    return el;
};

const loadedStylesheets = new Set();

export const loadStylesheet = (url) => {
    if (loadedStylesheets.has(url)) {
        return;
    }

    document.head.appendChild(
        htmlToElement(`<link href="${url}" rel="stylesheet" />`)
    );

    loadedStylesheets.add(url);
};
