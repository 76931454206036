import { $, $$ } from '../../utils';

const dcn = (suffix = '') => `.views-templates-SingleSelfStudyCourse${suffix}`;

$$(dcn()).forEach(($el) => {
    const $form = $(dcn('-form'), $el);

    if (!$form) {
        return;
    }

    const $questions = $$(dcn('-question'), $el);
    const $inputs = $$(dcn('-input'), $el);
    const $questionIncorrectMessages = $$(
        dcn('-questionIncorrectMessage'),
        $el
    );
    const $questionCorrectMessages = $$(dcn('-questionCorrectMessage'), $el);
    const $checkAnswersButton = $(dcn('-checkAnswersButton'), $el);
    const $correctAnswersContainer = $(dcn('-correctAnswersContainer'), $el);
    const $correctAnswersCount = $(dcn('-correctAnswersCount'), $el);
    const $retryContainer = $(dcn('-retryContainer'), $el);
    const $nextButton = $(dcn('-nextButton'), $el);
    const $completionMessage = $(dcn('-completionMessage'), $el);

    const { ajaxUrl, ajaxAction, ajaxNonce, courseId, moduleI } = $el.dataset;

    const getAnswerCount = () => $$(dcn('-input:checked'), $el).length;

    const handleInput = () => {
        $checkAnswersButton.disabled = getAnswerCount() < $questions.length;
    };

    const ajaxCompleteModule = () => {
        const body = new FormData();
        body.set('action', ajaxAction);
        body.set('_ajax_nonce', ajaxNonce);
        body.set('course', courseId);
        body.set('module', moduleI);

        return fetch(ajaxUrl, {
            method: 'POST',
            body,
        });
    };

    const handleCheckAnswersClick = (event) => {
        event.preventDefault();

        if (getAnswerCount() < $questions.length) {
            return;
        }

        $inputs.forEach(($input) => {
            $input.disabled = true;
        });

        let correctCount = 0;

        $questions.forEach(($question, i) => {
            const correct = !!$(dcn('-input[value="0"]:checked'), $question);

            if (correct) {
                correctCount += 1;
                $questionCorrectMessages[i].classList.remove('hidden');
            } else {
                $questionIncorrectMessages[i].classList.remove('hidden');
            }
        });

        $checkAnswersButton.classList.add('hidden');
        $correctAnswersCount.textContent = correctCount;
        $correctAnswersContainer.classList.remove('hidden');

        if (correctCount !== $questions.length) {
            $retryContainer.classList.remove('hidden');
        } else {
            ajaxCompleteModule()
                .catch((err) => {
                    // eslint-disable-next-line no-alert
                    alert(err.message);

                    throw err;
                })
                .then(() => {
                    $nextButton?.classList.remove('hidden');
                    $completionMessage?.classList.remove('hidden');
                });
        }
    };

    const handleNextClick = (event) => {
        event.preventDefault();

        $form.submit();
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    $checkAnswersButton.addEventListener('click', handleCheckAnswersClick);
    $nextButton?.addEventListener('click', handleNextClick);

    $form.addEventListener('input', handleInput);
    $form.addEventListener('submit', handleSubmit);
});
