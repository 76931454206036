import { $, $$ } from '../../../../utils';

const cn = (suffix = '') =>
    `views-components-molecules-forms-TextareaControl${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

$$(dcn()).forEach(($el) => {
    const $sizer = $(dcn('-sizer'), $el);
    const $textarea = $(dcn('-textarea'), $el);

    $textarea.addEventListener('input', () => {
        $sizer.innerText = `${$textarea.value} `;
    });
});
