import { $, $$, toggleClasses } from '../../../utils';

const { MutationObserver } = window;
const { body } = document;

const dcn = (suffix = '') => `.views-components-organisms-SiteHeader${suffix}`;

// eslint-disable-next-line import/prefer-default-export
export const setThemeClass = (themeClass, screen = 'xs') => {
    const prefix = screen === 'xs' ? '' : `${screen}:`;

    body.className = body.className.replace(
        new RegExp(`${prefix}theme-siteHeader-\\w+`),
        ''
    );
    body.className = `${body.className} ${prefix}${themeClass}`;
};

$$(dcn()).forEach((el) => {
    const $el = (suffix) => $(dcn(suffix), el);
    const $$el = (suffix) => $$(dcn(suffix), el);

    let mobileMenuIsOpen = false;
    let oldIsAtTop = true;

    // Shared

    let bodyLockScrollY = null;

    const lockBodyScroll = () => {
        if (bodyLockScrollY !== null) {
            return;
        }

        bodyLockScrollY = window.scrollY;

        body.style.setProperty('width', `${body.clientWidth}px`, 'important');
        body.style.setProperty('position', 'fixed', 'important');
        body.style.setProperty('top', `-${bodyLockScrollY}px`, 'important');
    };

    const unlockBodyScroll = () => {
        if (bodyLockScrollY === null) {
            return;
        }

        body.style.position = '';
        body.style.top = '';
        body.style.width = '';

        body.getClientRects();

        window.scrollTo(0, bodyLockScrollY);

        bodyLockScrollY = null;
    };

    // Theme

    const toggleThemeOnScroll = () => {
        if (bodyLockScrollY !== null) {
            return;
        }

        const isAtTop = window.scrollY <= 0;

        if (isAtTop === oldIsAtTop) {
            return;
        }

        if (!mobileMenuIsOpen) {
            el.classList.toggle('theme-siteHeader-white', !isAtTop);
        }

        oldIsAtTop = isAtTop;
    };

    const onAnimationFrame = () => {
        toggleThemeOnScroll();

        requestAnimationFrame(onAnimationFrame);
    };

    requestAnimationFrame(onAnimationFrame);

    // Mobile

    const modalOverlay = $el('-modalOverlay');
    const menu = $el('-mobileMenu');
    const openMobileMenuButtonContainer = $el('-openMobileMenuButtonContainer');
    const openMobileMenuButton = $el('-openMobileMenuButton');
    const closeMobileMenuButtonContainer = $el(
        '-closeMobileMenuButtonContainer'
    );
    const closeMobileMenuButton = $el('-closeMobileMenuButton');

    $$el('-mobileSectionContent').forEach((contentEl) => {
        const update = () => {
            contentEl.style.maxHeight = `${contentEl.scrollHeight}px`;
        };

        const mutationObserver = new MutationObserver(update);
        mutationObserver.observe(el, {
            attributes: true,
            childList: true,
            subtree: true,
        });

        window.addEventListener('resize', update);

        update();
    });

    const toggleMobileMenu = (open) => {
        if (open === mobileMenuIsOpen) {
            return;
        }

        if (open) {
            lockBodyScroll();

            el.classList.add('theme-siteHeader-transparentDark');
        } else {
            unlockBodyScroll();

            el.classList.remove('theme-siteHeader-transparentDark');

            if (!oldIsAtTop) {
                el.classList.add('theme-siteHeader-white');
            }
        }

        toggleClasses(openMobileMenuButtonContainer, {
            'opacity-0': open,
            'pointer-events-none': open,
        });

        toggleClasses(closeMobileMenuButtonContainer, {
            'opacity-0': !open,
            'pointer-events-none': !open,
        });

        toggleClasses(menu, {
            'opacity-0': !open,
            'pointer-events-none': !open,
        });

        mobileMenuIsOpen = open;
    };

    const openMobileMenu = () => {
        toggleMobileMenu(true);
    };

    const closeMobileMenu = () => {
        toggleMobileMenu(false);
    };

    openMobileMenuButton.addEventListener('click', openMobileMenu);
    closeMobileMenuButton.addEventListener('click', closeMobileMenu);

    // Desktop

    const mainItems = $$el('-mainItem');
    const mainItemLinks = mainItems.map((item) =>
        $(dcn('-mainItemLink'), item)
    );
    const mainItemFoldOuts = mainItems.map((item) =>
        $(dcn('-mainItemFoldOut'), item)
    );

    let currentMainItemFoldOutI = null;

    const onMainItemLinkClick = (e, i) => {
        const newFoldOut = mainItemFoldOuts[i];

        if (!newFoldOut) {
            return;
        }

        e.preventDefault();

        if (i === currentMainItemFoldOutI) {
            return;
        }

        const newLink = mainItemLinks[i];

        const oldLink = mainItemLinks[currentMainItemFoldOutI];
        const oldFoldOut = mainItemFoldOuts[currentMainItemFoldOutI];

        modalOverlay.classList.remove('opacity-0', 'pointer-events-none');
        modalOverlay.classList.add('opacity-20', 'cursor-pointer');

        oldLink?.classList.remove('is-open');
        oldFoldOut?.classList.add('opacity-0', 'pointer-events-none');

        newLink.classList.add('is-open');
        newFoldOut.classList.remove('opacity-0', 'pointer-events-none');

        currentMainItemFoldOutI = i;

        el.classList.add('theme-siteHeader-white');

        lockBodyScroll();
    };

    const onModalOverlayClick = () => {
        const oldFoldOut = mainItemFoldOuts[currentMainItemFoldOutI];
        const oldLink = mainItemLinks[currentMainItemFoldOutI];

        modalOverlay.classList.remove('opacity-20', 'cursor-pointer');
        modalOverlay.classList.add('opacity-0', 'pointer-events-none');

        oldLink?.classList.remove('is-open');
        oldFoldOut?.classList.add('opacity-0', 'pointer-events-none');

        currentMainItemFoldOutI = null;

        if (oldIsAtTop) {
            el.classList.remove('theme-siteHeader-white');
        }

        unlockBodyScroll();
    };

    mainItemLinks.forEach((link, i) =>
        link.addEventListener('click', (e) => onMainItemLinkClick(e, i))
    );

    modalOverlay.addEventListener('click', onModalOverlayClick);
});
