import { $, $$ } from '../../utils';

const cn = (suffix = '') => `views-templates-PageSkinEvaluation${suffix}`;
const dcn = (suffix = '') => `.${cn(suffix)}`;

$$(dcn()).forEach(($el) => {
    const $form = $(dcn('-form'), $el);

    if (!$form) {
        return;
    }

    const $startButton = $(dcn('-startButton'), $el);
    const $anchor = $(dcn('-anchor'), $el);
    const $progressContainer = $(dcn('-progressContainer'), $el);
    const $progress = $(dcn('-progress'), $el);
    const $steps = $$(dcn('-step'), $el);
    const $skinConditionsSimpleLabel = $(
        dcn('-skinConditions-simpleLabel'),
        $el
    );
    const $skinConditionsAdvancedLabel = $(
        dcn('-skinConditions-advancedLabel'),
        $el
    );
    const $skinConditionInputs = $$('[name="skin_conditions[]"]', $el);
    const $prevButton = $(dcn('-prevButton'), $el);
    const $nextButton = $(dcn('-nextButton'), $el);

    const $stepsById = $steps.reduce(($ss, $s) => {
        $ss[$s.dataset.step] = $s;
        return $ss;
    }, {});
    const steps = $steps.map(($s) => $s.dataset.step);

    const numMainSteps = $steps.reduce(
        (c, $step) => c + !$step.hasAttribute('data-supplemental'),
        0
    );

    const stepPredicates = {
        advanced_sun_block: (formData) => formData.get('level') === 'advanced',
        advanced_eye_product: (formData) =>
            formData.get('level') === 'advanced',
        melasma_type: (formData) =>
            formData.getAll('skin_conditions[]').includes('melasma'),
        acne_severity: (formData) =>
            formData.getAll('skin_conditions[]').includes('acne'),
        rosacea_severity: (formData) =>
            formData.getAll('skin_conditions[]').includes('rosacea'),
        seborrhoeic_scaly: (formData) =>
            formData.getAll('skin_conditions[]').includes('seborrhoeic'),
    };

    let activeSteps;
    let currentStep;

    const update = () => {
        const currentStepI = steps.indexOf(currentStep);
        const $currentStep = $stepsById[currentStep];

        const formData = new FormData($form);

        const currentInputNames = new Set(
            $$('input,select,textarea', $currentStep).map(($i) => $i.name)
        );

        const hasAllValueForCurrent = Array.from(currentInputNames).every(
            (name) => formData.get(name)
        );

        // Limit skin condition count

        const wantsAdvanced = formData.get('level') === 'advanced';
        const skinConditions = formData.getAll('skin_conditions[]');

        const hasNone = skinConditions.includes('none');
        const hasOther = !hasNone && skinConditions.length > 0;
        const maxCount = wantsAdvanced ? 3 : 1;
        const hasMax = skinConditions.length >= maxCount;
        skinConditions.splice(0, skinConditions.length - maxCount);

        $skinConditionsSimpleLabel.classList.toggle('hidden', wantsAdvanced);
        $skinConditionsAdvancedLabel.classList.toggle('hidden', !wantsAdvanced);

        $skinConditionInputs.forEach(($i) => {
            $i.checked = skinConditions.includes($i.value);
            $i.disabled =
                (hasNone && $i.value !== 'none') ||
                (hasOther && $i.value === 'none') ||
                (hasMax && !skinConditions.includes($i.value));
        });

        // Toggle $steps

        $steps.forEach(($step) =>
            $step.classList.toggle('hidden', $step !== $currentStep)
        );

        // Active Steps

        activeSteps = steps.filter((s) => {
            const predicate = stepPredicates[s];

            return predicate ? predicate(formData) : true;
        });

        // Progress

        let progress = -1;
        let progressI = 0;

        for (let i = 0; i <= currentStepI; i += 1) {
            const $step = $steps[i];

            if (!activeSteps.includes($step.dataset.step)) {
                continue;
            }

            if (!$step.hasAttribute('data-supplemental')) {
                progress += 1;
                progressI = i;
            }
        }

        let substepCount = 1;
        let subprogressI = 0;

        for (let i = progressI + 1; i < steps.length; i += 1) {
            if (!activeSteps.includes(steps[i])) {
                continue;
            }

            const $step = $steps[i];

            if ($step.hasAttribute('data-supplemental')) {
                substepCount += 1;

                if (i <= currentStepI) {
                    subprogressI += 1;
                }

                continue;
            }

            break;
        }

        if (substepCount > 1 && subprogressI > 0) {
            progress += subprogressI / substepCount;
        } else {
            progress += 1 / 12;
        }

        progress /= numMainSteps;

        $progress.style.setProperty('width', `${progress * 100}%`, 'important');

        // Toggle prev button

        $prevButton.classList.toggle('hidden', currentStepI === 0);

        // Toggle next button

        $nextButton.disabled = !hasAllValueForCurrent;
    };

    $startButton.addEventListener('click', (event) => {
        event.preventDefault();

        $startButton.classList.add('hidden');
        $progressContainer.classList.remove('hidden');
        $nextButton.classList.remove('hidden');

        [currentStep] = steps;

        update();

        $anchor.scrollIntoView({ behavior: 'smooth' });
    });

    $form.addEventListener('change', () => {
        update();
    });

    $form.addEventListener('submit', (event) => {
        event.preventDefault();
    });

    $prevButton.addEventListener('click', () => {
        currentStep = activeSteps[activeSteps.indexOf(currentStep) - 1];

        update();

        $anchor.scrollIntoView({ behavior: 'smooth' });
    });

    $nextButton?.addEventListener('click', () => {
        if ($nextButton.disabled) {
            return;
        }

        currentStep = activeSteps[activeSteps.indexOf(currentStep) + 1];

        if (currentStep) {
            update();

            $anchor.scrollIntoView({ behavior: 'smooth' });

            return;
        }

        $form.submit();
    });
});
