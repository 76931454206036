import smoothscroll from 'smoothscroll-polyfill';

import './views/components/molecules/forms/textarea-control';
import './views/components/organisms/academy-header';
import './views/components/organisms/archive-header';
import './views/components/organisms/front-page-hero';
import './views/components/organisms/product-header';
import './views/components/organisms/puffs';
import './views/components/organisms/questions-and-answers';
import './views/components/organisms/share-buttons';
import './views/components/organisms/site-header';
import './views/components/organisms/teacher-led-course-loop';
import './views/templates/page-stores';
import './views/templates/page-skin-evaluation';
import './views/templates/single-self-study-course';
import './real-100vw';
import './login';

smoothscroll.polyfill();
